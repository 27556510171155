import React, { useEffect, useState } from "react";
import { UseComboboxGetItemPropsOptions } from "downshift";
import ModalSelect from "./ModalSelect";
import DropdownSelect from "./DropdownSelect";
import useBreakpoint from "hooks/useBreakpoint";

export type SelectRenderItem<Item> = (
  item: Item,
  index: number,
  getItemProps: (options: UseComboboxGetItemPropsOptions<Item>) => any,
  helpers?: {
    isFirst: boolean;
    isHighlighted: boolean;
    closeModal?: () => void;
    selectItem: (item: Item) => void;
    itemToString: (item: Item | null) => string;
  }
) => React.ReactElement;

export type CommonSelectProps<Item> = {
  /**
   * It must be the same on server and client. It is required to
   * prevent id mismatch between server-rendered content and client.
   *
   * It is also used as the modal id to control the ModalSelect by route.
   *
   * See Also: {@link useRoutedModal}, {@link ModalSelect}
   *
   * */
  id: string;
  /**
   * The local name of this select. for example "شهر" or "تخصص" . It will be used
   * to construct the default `placeholder`, `modalTitle` and `modalInputPlaceholder`
   * */
  generalName: string;
  /** available options. **it MUST be memoized** */
  items: Array<Item>;
  /** Controls the `selectedItem` of downshift */
  value: Item | null;
  /** called when the `slectedItem` changes or gets deselected */
  onChange: (selectedItem: Item | null) => void;
  /**
   * return the string representation of any given item.
   * used for internal search and a11y.
   *
   * **it MUST be memoized**
   * */
  itemToString: (item: Item | null) => string;
  /**
   * It is used to get a searchable string for each item.
   * the string will not be rendered anywhere
   *
   * **it MUST be memoized**
   */
  itemToSearchString?: (item: Item | null) => string;
  /** while true, component becomes un-intractable and a spinner is displayed */
  isLoading?: boolean;
  /**
   * Use this if you want to implement your custom search functionality.
   * It is useful if items are searched on server.
   *
   * Pass `undefined` and the internal fuzzy search is used to filter items
   * based on their string representation provided by `itemToSearchString` or `itemToString`
   * */
  onSearch?: (text: string) => void;
  /** placeholder of the combobox input */
  placeholder?: string;
  /** Default selected item when select gets reset */
  defaultValue?: Item | null;
  /** If true, a reset button will appear when an item is selected */
  isClearable?: boolean;
  isDisabled?: boolean;
  /**
   * This is applied to the element with input-like styles.
   * (not the root container of component)
   *
   * It can be used to customize the border, background, hover styles, etc.
   * */
  className?: string;
  /**
   * Render function that renders a single Item.
   *  you must apply `{...getItemProps({item, index})}` on the element
   *  if you want to set onClick on the item element, it must be passed after `{...getItemProps({item, index})}`
   *  otherwise, it will cause a bug on iOS devices that will scroll the page to the bottom on item select.
   *  if you need to select the item onClick, you should use `helpers.selectItem(item)`
   *  */
  renderItem?: SelectRenderItem<Item>;
  /**
   *  It will render a custom element as the selectedItem.
   *  It will replace the combobox input on desktop
   */
  renderSelectedItem?: (item: Item) => React.ReactElement;

  /** If true, the border of drop down will be red*/
  hasError?: boolean;
  /** The name attribute will be set on <input> element (DropdownSelect) or <button> (ModalSelect) */
  name?: string;
  onBlur?: (e: React.FocusEvent) => void;
};

export type SelectProps<Item> = CommonSelectProps<Item> & {
  /** If true, the component will be in modal mode on both mobile and desktop screens. */
  isAlwaysInModal?: boolean;
  /** placeholder of the combobox input in modal*/
  modalInputPlaceholder?: string;
  modalTitle?: string;
};

function Select<Item>(props: SelectProps<Item>): React.ReactElement {
  const {
    modalTitle,
    modalInputPlaceholder,
    isAlwaysInModal = false,
    ...commonProps
  } = props;

  const { isDesktopScreen } = useBreakpoint();

  // It is initially false, then gets updated by an effect
  // Providing a static initial value will prevent html mismatch between server and client
  const [isInModalMode, setIsInModalMode] = useState(true);

  // const defaultStrings = useMemo(() => {
  //   return {
  //     placeholder: generalName + " موردنظر را انتخاب کنید",
  //     modalTitle: "انتخاب " + generalName,
  //     modalInputPlaceholder: "جستجو در " + generalName + "‌ها",
  //   };
  // }, [generalName]);

  // Update the mode based on screen changes
  useEffect(() => {
    setIsInModalMode(isAlwaysInModal ? true : !isDesktopScreen);
  }, [isDesktopScreen, isAlwaysInModal]);

  return (
    <>
      {isInModalMode ? (
        <ModalSelect<Item>
          title={modalTitle}
          modalInputPlaceholder={modalInputPlaceholder}
          {...commonProps}
        />
      ) : (
        <DropdownSelect<Item> {...commonProps} />
      )}
    </>
  );
}

export default Select;
