import styled from "styled-components";
import { MenuUl } from "../styles";
import { SelectTheme } from "../theme";
import { getResponsiveCssRules } from "styles/helpers";

export const SelectedButton = styled.button<{
  isSelected: boolean;
  theme: SelectTheme;
}>`
  display: flex;
  align-items: center;
  outline: none;
  border: 0;
  background-color: transparent;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ isSelected, theme, disabled }) => {
    if (isSelected) {
      if (disabled) {
        return theme.input.textColor.disabled;
      }
      return theme.input.textColor.normal;
    } else {
      if (disabled) {
        return theme.input.placeholderColor.disabled;
      }
      return theme.input.placeholderColor.normal;
    }
  }};
  ${({ theme }) =>
    getResponsiveCssRules("padding", theme.select.toggleButton.padding)}
`;

export const InputWrapper = styled.div`
  display: flex;
  height: 40px;
  flex-shrink: 0;
  width: calc(100% - 30px);
  margin: 0 auto;
  border-bottom: 1px solid rgba(65, 134, 255, 0.5);
`;

export const Ul = styled(MenuUl)`
  max-height: initial;
  height: calc(70vh - 85px);
  position: static;
  box-shadow: none;
`;
