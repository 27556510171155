import styled from "styled-components";
import {
  getResponsiveHeightRules,
  getResponsiveWidthRules,
} from "styles/helpers";
import { SelectTheme } from "../theme";

export const ClearButton = styled.button<{ theme: SelectTheme }>`
  border: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  ${({ theme }) => getResponsiveHeightRules(theme.select.clearButton.height)};
  ${({ theme }) => getResponsiveWidthRules(theme.select.clearButton.width)};
  flex: 0 0 auto;
  align-self: center;
  border-radius: 50%;

  &:hover,
  &:focus {
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.4);
  }
`;
