import deepmerge from "deepmerge";
import * as Styled from "./styles";
import SearchIcon from "svg/search.svg";
import ClearButton from "../ClearButton";
import SvgIcon from "components/SvgIcon";
import * as CommonStyled from "../styles";
import usePrevious from "hooks/usePrevious";
import { CommonSelectProps } from "../Select";
import ArrowDownIcon from "svg/arrow-down.svg";
import React, { useEffect, useMemo, useRef } from "react";
import { defaultSelectTheme, SelectTheme } from "../theme";
import PulseSpinner from "components/Spinners/PulseSpinner";
import { useSelect } from "components/Inputs/Select/useSelect";
import { ModalWithHeader, useModal } from "components/Modal";
import NoResultFoundItem from "../NoResultFoundItem/NoResultFoundItem";
import { DefaultTheme, ThemeProvider, useTheme } from "styled-components";
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from "downshift";
import { useTranslation } from "react-i18next";

type Props<Item> = CommonSelectProps<Item> & {
  title?: string;
  modalInputPlaceholder?: string;
};

const modalWidth = { xs: "90vw", sm: "500px" };

/**
 * Always clear the input when an item is selected, otherwise the items will be filtered
 * by the string value of selectedItem.
 * because selecting an item updates the inputValue with the string value of the selectedItem
 * by default and if the `inputValue` equals the string value of new `selectedItem`,
 * the `inputValue` remains the same, so it doesn't trigger the `handleSearch` function
 * which clears the filteredItems when the inputChange event is caused by selecting an item!
 */
function stateReducer<Item>(
  state: UseComboboxState<Item>,
  actionAndChanges: UseComboboxStateChangeOptions<Item>
) {
  const { type, changes } = actionAndChanges;

  const inputValue =
    type === useCombobox.stateChangeTypes.FunctionSelectItem ||
    type === useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem
      ? ""
      : changes.inputValue;

  return {
    ...changes,
    inputValue,
    isOpen: true,
  };
}

/**
 * A Select that shows the items in a Modal.
 *
 * It gets a unique `id` and use it to handle the opening of the Modal by route.
 *
 * @constructor
 */
function ModalSelect<Item>(props: Props<Item>): React.ReactElement {
  const scrollableElementRef = useRef<HTMLElement | null>(null);

  const {
    id,
    name,
    items,
    title,
    value,
    onBlur,
    hasError,
    onChange,
    onSearch,
    className,
    renderItem,
    placeholder,
    generalName,
    itemToString,
    isLoading = false,
    isDisabled = false,
    isClearable = true,
    defaultValue = null,
    renderSelectedItem,
    itemToSearchString,
    modalInputPlaceholder,
  } = props;

  const { isOpen, handleOpenModal, handleCloseModal } = useModal();

  const {
    reset,
    inputValue,
    selectItem,
    getItemProps,
    selectedItem,
    setInputValue,
    getMenuProps,
    getInputProps,
    filteredItems,
    highlightedIndex,
    getComboboxProps,
    isOpen: isMenuOpen,
  } = useSelect({
    id,
    items,
    value,
    onChange,
    onSearch,
    itemToString,
    defaultValue,
    stateReducer,
    itemToSearchString,
  });

  const parentTheme = useTheme();

  const { t: translate } = useTranslation("common");

  const theme = useMemo(() => {
    return deepmerge<SelectTheme, DefaultTheme>(
      defaultSelectTheme,
      parentTheme
    );
  }, [parentTheme]);

  const wasMenuOpen = usePrevious<boolean>(isMenuOpen);

  useEffect(() => {
    if (wasMenuOpen && !isMenuOpen) {
      handleCloseModal();
    }
  }, [handleCloseModal, isMenuOpen, wasMenuOpen]);

  const defaultTexts = {
    placeholder: generalName + translate("selectChooseItem"),
    modalTitle: translate("choose") + generalName,
    modalInputPlaceholder: translate("selectSearchPlaceHolder") + generalName,
  };

  return (
    <ThemeProvider theme={theme}>
      <CommonStyled.SelectContainer
        disabled={isDisabled}
        className={className}
        hasError={hasError}
      >
        <Styled.SelectedButton
          name={name}
          type="button"
          aria-label={placeholder}
          onClick={handleOpenModal}
          isSelected={!!selectedItem}
          disabled={isDisabled || isLoading}
          onBlur={onBlur}
        >
          {selectedItem
            ? renderSelectedItem?.(selectedItem) ?? itemToString(selectedItem)
            : placeholder || defaultTexts.placeholder}
        </Styled.SelectedButton>

        {isLoading ? (
          <PulseSpinner
            size={5}
            style={{ marginRight: 5, alignSelf: "center" }}
          />
        ) : selectedItem && isClearable && !isDisabled ? (
          <ClearButton
            type={"button"}
            onClick={reset}
            disabled={isDisabled}
            style={{ marginRight: 6 }}
          />
        ) : (
          <CommonStyled.ToggleButton
            type={"button"}
            onClick={handleOpenModal}
            aria-label={translate("selectMoreResults")}
            disabled={isDisabled}
          >
            <SvgIcon
              component={ArrowDownIcon}
              fill={
                isDisabled
                  ? theme.select.toggleButton.icon.color.disabled
                  : theme.select.toggleButton.icon.color.normal
              }
              size={theme.select.toggleButton.icon.size}
            />
          </CommonStyled.ToggleButton>
        )}
      </CommonStyled.SelectContainer>

      <ModalWithHeader
        title={title ?? defaultTexts.modalTitle}
        isOpen={isOpen}
        onClose={handleCloseModal}
        width={modalWidth}
        scrollableElementRef={scrollableElementRef}
      >
        <Styled.InputWrapper
          {...getComboboxProps(
            { disabled: isDisabled },
            { suppressRefError: true }
          )}
        >
          <SvgIcon
            component={SearchIcon}
            size={{ xs: "14px" }}
            fill="rgba(65, 134, 255, 0.5)"
            style={{ marginRight: 5, alignSelf: "center" }}
          />
          <CommonStyled.Input
            {...getInputProps(
              {
                placeholder:
                  modalInputPlaceholder || defaultTexts.modalInputPlaceholder,
              },
              { suppressRefError: true }
            )}
          />

          {inputValue ? (
            <ClearButton onClick={() => setInputValue("")} />
          ) : null}
        </Styled.InputWrapper>

        <Styled.Ul
          {...getMenuProps(
            { ref: scrollableElementRef },
            { suppressRefError: true }
          )}
          isOpen
        >
          {isOpen ? (
            filteredItems.length === 0 ? (
              <NoResultFoundItem />
            ) : (
              filteredItems.map((item, index) => {
                const isHighlighted = highlightedIndex === index;
                const isFirst = index === 0;

                if (renderItem) {
                  return renderItem(item, index, getItemProps, {
                    isFirst,
                    selectItem,
                    itemToString,
                    isHighlighted,
                    closeModal: handleCloseModal,
                  });
                }

                return (
                  <CommonStyled.Li
                    isHighlighted={highlightedIndex === index}
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                    // this overwrites downshift's internal onClick (which dispatches "itemClick" action and then focuses the input)
                    // because focusing the input will open the keyboard for a moment before closing the modal (not desired)
                    // and scrolls the page to the bottom on iphone.
                    onClick={() => {
                      selectItem(item);
                      handleCloseModal();
                    }}
                  >
                    <CommonStyled.Item isFirst={index === 0}>
                      {itemToString(item)}
                    </CommonStyled.Item>
                  </CommonStyled.Li>
                );
              })
            )
          ) : null}
        </Styled.Ul>
      </ModalWithHeader>
    </ThemeProvider>
  );
}

export default ModalSelect;
