import deepmerge from "deepmerge";
import * as Styled from "./styles";
import ClearButton from "../ClearButton";
import SvgIcon from "components/SvgIcon";
import * as CommonStyled from "../styles";
import { CommonSelectProps } from "../Select";
import ArrowDownIcon from "svg/arrow-down.svg";
import React, { useCallback, useMemo } from "react";
import { defaultSelectTheme, SelectTheme } from "../theme";
import PulseSpinner from "components/Spinners/PulseSpinner";
import { useSelect } from "components/Inputs/Select/useSelect";
import NoResultFoundItem from "../NoResultFoundItem/NoResultFoundItem";
import { DefaultTheme, ThemeProvider, useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

function DropdownSelect<Item>(
  props: CommonSelectProps<Item>
): React.ReactElement {
  const {
    id,
    name,
    items,
    value,
    onBlur,
    hasError,
    onChange,
    onSearch,
    className,
    renderItem,
    placeholder,
    generalName,
    itemToString,
    isLoading = false,
    isDisabled = false,
    isClearable = true,
    defaultValue = null,
    renderSelectedItem,
    itemToSearchString,
  } = props;

  const {
    reset,
    isOpen,
    openMenu,
    closeMenu,
    selectItem,
    getItemProps,
    selectedItem,
    getMenuProps,
    filteredItems,
    getInputProps,
    highlightedIndex,
    getComboboxProps,
  } = useSelect({
    id,
    items,
    value,
    onChange,
    onSearch,
    itemToString,
    defaultValue,
    itemToSearchString,
  });

  const parentTheme = useTheme();
  const { t: translate } = useTranslation("common");
  const theme = useMemo(() => {
    return deepmerge<SelectTheme, DefaultTheme>(
      defaultSelectTheme,
      parentTheme
    );
  }, [parentTheme]);

  const handleCloseMenu = useCallback(() => {
    if (!isOpen) {
      openMenu();
    }
  }, [openMenu, isOpen]);

  return (
    <ThemeProvider theme={theme}>
      <CommonStyled.SelectContainer
        className={className}
        disabled={isDisabled}
        hasError={hasError}
      >
        <Styled.InputContainer
          {...getComboboxProps({ disabled: isDisabled, autoComplete: "off" })}
        >
          {renderSelectedItem && selectedItem ? (
            renderSelectedItem(selectedItem)
          ) : (
            <CommonStyled.Input
              {...getInputProps({
                name,
                placeholder: translate("selectChooseItem").concat(
                  placeholder || generalName
                ),
                disabled: isDisabled,
                onFocus: handleCloseMenu,
                onBlur,
                autoComplete: "off",
              })}
            />
          )}

          {isLoading ? (
            <PulseSpinner
              size={5}
              style={{ marginRight: 5, alignSelf: "center" }}
            />
          ) : selectedItem && isClearable && !isDisabled ? (
            <ClearButton
              type={"button"}
              onClick={reset}
              disabled={isDisabled}
              style={{ marginRight: 6 }}
            />
          ) : (
            <CommonStyled.ToggleButton
              type={"button"}
              onClick={openMenu}
              aria-label={translate("selectMoreResults")}
              disabled={isDisabled}
            >
              <SvgIcon
                component={ArrowDownIcon}
                fill={
                  isDisabled
                    ? theme.select.toggleButton.icon.color.disabled
                    : theme.select.toggleButton.icon.color.normal
                }
                size={theme.select.toggleButton.icon.size}
              />
            </CommonStyled.ToggleButton>
          )}
        </Styled.InputContainer>
        <CommonStyled.MenuUl {...getMenuProps()} isOpen={isOpen}>
          {isOpen ? (
            filteredItems.length === 0 ? (
              <NoResultFoundItem />
            ) : (
              filteredItems.map((item, index) => {
                const isHighlighted = highlightedIndex === index;
                const isFirst = index === 0;

                if (renderItem) {
                  return renderItem(item, index, getItemProps, {
                    isFirst,
                    isHighlighted,
                    selectItem,
                    itemToString,
                  });
                }

                return (
                  <CommonStyled.Li
                    isHighlighted={highlightedIndex === index}
                    key={`${item}${index}`}
                    {...getItemProps({ item, index, onClick: closeMenu })}
                  >
                    <CommonStyled.Item isFirst={index === 0}>
                      {itemToString(item)}
                    </CommonStyled.Item>
                  </CommonStyled.Li>
                );
              })
            )
          ) : null}
        </CommonStyled.MenuUl>
      </CommonStyled.SelectContainer>
    </ThemeProvider>
  );
}

export default DropdownSelect;
