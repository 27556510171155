import React, { ButtonHTMLAttributes } from "react";
import * as Styled from "./styles";
import SvgIcon from "components/SvgIcon";
import CloseIcon from "svg/close.svg";

const ClearButton = (
  props: ButtonHTMLAttributes<HTMLButtonElement>
): React.ReactElement => (
  <Styled.ClearButton {...props} type="button" aria-label={"delete"}>
    <SvgIcon component={CloseIcon} size={{ xs: 9 }} fill="#6c757d" />
  </Styled.ClearButton>
);

export default ClearButton;
