import { useCallback, useEffect, useMemo, useState } from "react";
import breakpoints, { ResponsiveKeys } from "styles/breakpoints";

type ReturnType = {
  breakpoint: string | undefined;
  isMobileScreen: boolean | undefined;
  isTabletScreen: boolean | undefined;
  isDesktopScreen: boolean | undefined;
};

/**
 *  It will always return the current breakpoint or undefined if it's used on server
 */
export default function useBreakpoint(): ReturnType {
  const [BREAKPOINTS, BREAKPOINT_NAMES] = useMemo(() => {
    const breakpointSizes: Array<number> = [];
    const breakpointNames: Array<string> = [];
    const breakpointsArray = Object.keys(breakpoints).map((key) => {
      const size = breakpoints[key as ResponsiveKeys];
      return { name: key, size: parseInt(size.replace(/(px)|(em)|(rem)/, "")) };
    });
    breakpointsArray
      .sort((a, b) => b.size - a.size)
      .forEach((item) => {
        breakpointNames.push(item.name);
        breakpointSizes.push(item.size);
      });
    return [breakpointSizes, breakpointNames];
  }, []);

  const mediaQueryLists = useMemo(() => {
    if (isServer()) {
      return;
    }

    return BREAKPOINTS.map((breakpoint) =>
      window.matchMedia(`(min-width: ${breakpoint}px)`)
    );
  }, [BREAKPOINTS]);

  // finds the most specific query that matches
  const findMatchedMedia = useCallback(() => {
    if (!mediaQueryLists) {
      return;
    }

    const index = mediaQueryLists.findIndex(
      (mediaQueryList) => mediaQueryList.matches
    );

    if (index !== undefined && index >= 0) {
      return BREAKPOINT_NAMES[index];
    } else {
      return "xs";
    }
  }, [BREAKPOINT_NAMES, mediaQueryLists]);

  // Holds the name of matched media
  const [matchedMedia, setMatchedMedia] = useState(
    isServer() ? undefined : findMatchedMedia()
  );

  // Adds a change listener to all media queries
  useEffect(() => {
    if (!mediaQueryLists) {
      return;
    }

    const handler = () => setMatchedMedia(findMatchedMedia);
    mediaQueryLists.forEach((mediaQueryList) => {
      // addEventListener is not supported on safari and IE:
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
      mediaQueryList.addListener(handler);
    });

    // Cleanup
    return () => {
      mediaQueryLists.forEach((mediaQueryList) => {
        mediaQueryList.removeListener(handler);
      });
    };
  }, [mediaQueryLists, findMatchedMedia]);

  const isMobileScreen =
    matchedMedia === undefined
      ? undefined
      : matchedMedia === "xs" || matchedMedia === "sm";
  const isTabletScreen =
    matchedMedia === undefined ? undefined : matchedMedia === "md";
  const isDesktopScreen =
    matchedMedia === undefined
      ? undefined
      : matchedMedia === "lg" ||
        matchedMedia === "xl" ||
        matchedMedia === "xxl";

  return {
    breakpoint: matchedMedia,
    isMobileScreen,
    isTabletScreen,
    isDesktopScreen,
  };
}

function isServer() {
  return !(typeof window != "undefined" && window.document);
}
