import {
  useCombobox,
  UseComboboxReturnValue,
  UseComboboxState,
  UseComboboxStateChange,
  UseComboboxStateChangeOptions,
} from "downshift";
import { SelectProps } from "components/Inputs/Select/Select";
import { useCallback, useEffect, useMemo, useState } from "react";
import fuzzaldrin from "fuzzaldrin";

type UseSelectProps<Item> = Pick<
  SelectProps<Item>,
  | "id"
  | "items"
  | "value"
  | "onChange"
  | "onSearch"
  | "itemToString"
  | "defaultValue"
  | "itemToSearchString"
> & {
  stateReducer?: (
    state: UseComboboxState<Item>,
    actionAndChanges: UseComboboxStateChangeOptions<Item>
  ) => Partial<UseComboboxState<Item>>;
};

type UseSelectReturnValue<Item> = UseComboboxReturnValue<Item> & {
  filteredItems: Array<Item>;
};

type SearchableItem = {
  stringValue: string;
  index: number;
};

export function useSelect<Item>(
  props: UseSelectProps<Item>
): UseSelectReturnValue<Item> {
  const {
    id,
    items,
    value,
    onChange,
    onSearch,
    itemToString,
    itemToSearchString,
    defaultValue = null,
    stateReducer,
  } = props;

  const [filteredItems, setFilteredItems] = useState<Array<Item>>(items);

  const searchableItems = useMemo<SearchableItem[]>(() => {
    return items.map((item, index) => ({
      stringValue: itemToSearchString?.(item) ?? itemToString(item),
      index,
    }));
  }, [itemToSearchString, itemToString, items]);

  // Reset filteredItems to new items
  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const handleChangeSelectedItem = useCallback(
    ({ selectedItem }: UseComboboxStateChange<Item>): void => {
      onChange(selectedItem || null);
    },
    [onChange]
  );

  const handleSearch = useCallback(
    ({ inputValue, type }: UseComboboxStateChange<Item>): void => {
      if (inputValue && type === useCombobox.stateChangeTypes.InputChange) {
        if (onSearch) {
          onSearch(inputValue);
        } else {
          const filteredItems: Item[] = fuzzaldrin
            .filter(searchableItems, inputValue, { key: "stringValue" })
            .map((item) => items[item.index]);

          setFilteredItems(filteredItems);
        }
      } else {
        if (onSearch) {
          onSearch("");
        } else {
          setFilteredItems(items);
        }
      }
    },
    [items, onSearch, searchableItems]
  );

  const useComboboxReturnValue = useCombobox<Item>({
    id,
    items: filteredItems,
    selectedItem: value || null,
    defaultSelectedItem: defaultValue,
    onSelectedItemChange: handleChangeSelectedItem,
    itemToString,
    onInputValueChange: handleSearch,
    ...(stateReducer && { stateReducer }),
  });

  return {
    ...useComboboxReturnValue,
    filteredItems,
  };
}
